import React, { useEffect, useState } from "react";
import style from "./Menu-bar.module.scss";
import home from "../../assets/images/home.svg";
import bag from "../../assets/images/bag.svg";
import money from "../../assets/images/money.svg";
import building from "../../assets/images/building.svg";
import analytic from "../../assets/images/analytics.svg";
import finance from "../../assets/images/finance.svg";
import room from "../../assets/images/rooms.svg";
import rating from "../../assets/images/rating.svg";
import { IoIosArrowDown } from "react-icons/io";
import { Link } from "react-router-dom";
import Container from "../container";
import { useLocation } from "react-router-dom";

const MenuBar = () => {
  const location = useLocation();
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [activeMenu, setActiveMenu] = useState(null);
  const MENU = [
    {
      icon: home,
      name: "Home",
      link: "/?order_status=order_confirmed&analytics=weekly",
    },
    { icon: bag, name: "Bookings", link: "/bookings?days=0" },
    {
      icon: building,
      name: "property",
      dropdownItems: [
        { title: "Property Info", link: "/property/info" },
        { title: "Property Image", link: "/property/image" },
        { title: "Amenities", link: "/property/amenities" },
      ],
    },
    { icon: room, name: "rooms", link: "/rooms" },
    {
      icon: money,
      name: "pricing",
      dropdownItems: [
        { title: "Calender", link: "/pricing/calender" },
        { title: "Price per guest", link: "/pricing/price-per-guest" },
      ],
    },
    { icon: finance, name: "finance", link: "/finance" },
    { icon: analytic, name: "analytics", link: "/analytics" },
    { icon: rating, name: "rating and review", link: "/rating-and-review" },
  ];

  function fetchActiveMenu() {
    if (location.pathname === "/") return setActiveMenu(0);
    let activeLink = MENU.findIndex(
      (item) => item.name.split(" ").join("-").toLowerCase() === location.pathname.split("/")[1]
    );
    setActiveMenu(activeLink);
  }

  useEffect(() => {
    fetchActiveMenu();
  }, [location]);

  const handleMouseEnter = (index) => {
    setActiveDropdown(index);
  };

  const handleMouseLeave = () => {
    setActiveDropdown(null);
  };

  const handleClick = (index) => {
    setActiveMenu(index);
  };

  return (
    <Container>
      <div className={style.menus}>
        {MENU.map((item, index) => {
          return (
            <div
              className={style.menu_items}
              key={index}
              onMouseLeave={handleMouseLeave}
              onClick={() => handleClick(index)}
            >
              <div
                className={`${style.menu_wrapper} ${
                  activeMenu === index ? style.activeMenu : ""
                }`}
                onMouseEnter={() => handleMouseEnter(index)}
              >
                {item?.link ? (
                  <Link to={item?.link} className={style.link}>
                    <div className={style.name}>
                      <img src={item?.icon} alt={item?.name} />
                      <span>{item?.name}</span>
                    </div>
                  </Link>
                ) : (
                  <div className={style.name}>
                    <img src={item?.icon} alt={item?.name} />
                    <span>{item?.name}</span>
                  </div>
                )}

                {item?.dropdownItems && (
                  <div className={style.arrow}>
                    <IoIosArrowDown />
                  </div>
                )}
              </div>
              {item?.dropdownItems && (
                <div className={style.dropdown_items}>
                  {activeDropdown === index && (
                    <div className={style.dropdown}>
                      {item.dropdownItems.map((subItem, subIndex) => (
                        <div className={style.dropdown_item} key={subIndex}>
                          <Link to={subItem?.link}>{subItem?.title}</Link>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              )}
            </div>
          );
        })}
      </div>
    </Container>
  );
};
export default MenuBar;
