import React, { useState } from "react";
import style from "./Header.module.scss";
import logo from "../../assets/images/logo.png";
import Container from "../container";
import { BsQuestionCircle } from "react-icons/bs";
import CountryDropdown from "../countries";
import { useDispatch, useSelector } from "react-redux";
import { selectIsLoggedIn } from "../../features/userSlice";
import photo from "../../assets/images/user.svg";
import { useLocation, useNavigate } from "react-router-dom";
import ClickAwayListener from "react-click-away-listener";

import { clearUser } from "../../features/userSlice";
import { Link } from "react-router-dom";
import PropertyList from "../propert-toggle-header";
import placeholder from "../../assets/images/profile-placeholder.jpg";
import profile from "../../assets/images/profile-icon.svg";
import notification from "../../assets/images/notification-icon.svg";
import logout from "../../assets/images/logout-icon.svg";

const Header = ({ showPropertyList }) => {
  const [show, setShow] = useState(false);
  const { pathname } = useLocation();

  const isLoggedIn = useSelector(selectIsLoggedIn);

  const user = useSelector((state) => state?.user?.user);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const handleLogout = () => {
    dispatch(clearUser());
    localStorage.removeItem("token");
    navigate("/login", { replace: true });
    // navigate(0)
  };

  return (
    <div className={style.header_container}>
      <Container>
        <div className={style.content_wrapper}>
          <Link
            className={style.logo_wrapper}
            to={pathname !== "/property-onboarding" && pathname !== '/login' ? "/?order_status=order_confirmed&analytics=weekly" : "#"}
          >
            <img src={logo} alt="logo" />
          </Link>
          <div className={style.header_wrapper}>
            {isLoggedIn && (
              <>
                {showPropertyList && (
                  <div className={style.property_detail}>
                    <PropertyList />
                  </div>
                )}
              </>
            )}
            {/* <div className={style.question}>
              <BsQuestionCircle />
            </div>
            <div className={style.country}>
              <CountryDropdown />
            </div> */}
            {isLoggedIn && (
              <div className={style.profile}>
                <img
                  src={
                    user?.user_profile_image
                      ? user?.user_profile_image
                      : placeholder
                  }
                  alt={user?.first_name}
                  onClick={(e) => {
                    e.stopPropagation();
                    setShow(!show);
                  }}
                />
                <ClickAwayListener
                  onClickAway={(e) => {
                    setShow(false);
                  }}
                >
                  <div
                    className={`${style.toggle} ${
                      show
                        ? style.openModelAnimation
                        : style.closeModelAnimation
                    }`}
                  >
                    {pathname !== "/property-onboarding" && (
                      <button
                        onClick={(e) => {
                          setShow(false);
                          navigate("/profile");
                        }}
                      >
                        <img src={profile} alt="logout" />
                        <span>Profile</span>
                      </button>
                    )}
                    <button
                      onClick={(e) => {
                        setShow(false);
                        handleLogout();
                      }}
                    >
                      <img src={logout} alt="logout" />
                      <span>logout</span>
                    </button>
                  </div>
                </ClickAwayListener>
              </div>
            )}
          </div>
        </div>
      </Container>
    </div>
  );
};
export default Header;
