import { createSlice } from "@reduxjs/toolkit";

const propertySlice = createSlice({
    name: 'properties',
    initialState: {
        properties: [],
        activeProperty: null,
    },
    reducers: {
        setProperties: (state, action) => {
            state.properties = action.payload;
            state.activeProperty = null;
        },
        setActiveProperty: (state, action) => {
            state.activeProperty = action.payload;
        },
    },
});

export const { setProperties, setActiveProperty } = propertySlice.actions;

export default propertySlice.reducer;
