import React, { useState, useEffect } from "react";
import style from "./Property-toggle.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { setActiveProperty } from "../../features/propertiesSlice";
import ClickAwayListener from "react-click-away-listener";
import { useLocation } from "react-router";

const PropertyList = () => {
  const { pathname } = useLocation();
  const [toggle, setToggle] = useState(false);
  const property = useSelector((state) => state.properties.properties);
  const activeProperty = useSelector(
    (state) => state.properties.activeProperty
  );
  const dispatch = useDispatch();

  const handleClick = (e) => {
    e.stopPropagation();
    if (!pathname.includes("/add-rooms")) {
      setToggle(!toggle);
    }
  };

  const handleClickAway = () => {
    setToggle(false);
  };

  const handlePropertyClick = (property) => {
    dispatch(setActiveProperty(property));
    localStorage.setItem("active_property", property?.id);
    setToggle(false);
  };

  return (
    <div className={style.property_detail_wrapper}>
      {activeProperty && (
        <>
          <span className={style.name}>{activeProperty.name}</span>
          <span className={style.number}>{activeProperty.id}</span>
        </>
      )}
      <button type="button" onClick={handleClick}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="8"
          height="16"
          viewBox="0 0 8 16"
          fill="none"
        >
          <path
            d="M3.99998 1.72005C3.8566 1.72005 3.71324 1.77479 3.60393 1.88405L0.164113 5.32391C-0.0547044 5.54272 -0.0547044 5.8975 0.164113 6.11623C0.382842 6.33496 0.737546 6.33496 0.956381 6.11623L3.99998 3.07245L7.04359 6.11612C7.26241 6.33485 7.61708 6.33485 7.83579 6.11612C8.05471 5.89739 8.05471 5.54262 7.83579 5.3238L4.39602 1.88394C4.28666 1.77467 4.1433 1.72005 3.99998 1.72005Z"
            fill="white"
          />
          <path
            d="M3.99998 14.278C3.8566 14.278 3.71324 14.2233 3.60393 14.114L0.164113 10.6741C-0.0547044 10.4553 -0.0547044 10.1005 0.164113 9.88182C0.382842 9.66309 0.737546 9.66309 0.956381 9.88182L3.99998 12.9256L7.04359 9.88193C7.26241 9.6632 7.61708 9.6632 7.83579 9.88193C8.05471 10.1007 8.05471 10.4554 7.83579 10.6742L4.39602 14.1141C4.28666 14.2234 4.1433 14.278 3.99998 14.278Z"
            fill="white"
          />
        </svg>
      </button>
      <div className={style.property_dropdown}>
        {property && (
          <ClickAwayListener onClickAway={handleClickAway}>
            <div
              className={`${style.dropdown} ${
                toggle ? style.openModelAnimation : style.closeModelAnimation
              }`}
            >
              {property?.map((data, index) => (
                <div
                  key={index}
                  className={style.property_data}
                  onClick={() => handlePropertyClick(data)}
                >
                  <span className={style.name}>{data?.name}</span>
                  <span className={style.number}>{data?.id}</span>
                </div>
              ))}
            </div>
          </ClickAwayListener>
        )}
      </div>
    </div>
  );
};

export default PropertyList;
