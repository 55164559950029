import "./App.css";
import { BrowserRouter as Router } from "react-router-dom";
import AppRouter from "./components/router/router";
import { Provider, useDispatch, useSelector } from "react-redux";
import { store } from "../src/store/store";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { setUser, selectIsLoggedIn } from "./features/userSlice";
import { Suspense, useEffect, useState } from "react";
import { http } from "./utilits/http";
import Loader from "./components/loader";
import { setActiveProperty, setProperties } from "./features/propertiesSlice";
import AuthProvider from "./components/authProvider";

function App() {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(selectIsLoggedIn);

  return (
    <Provider store={store}>
      {loading && <Loader />}
      {/* <AuthProvider /> */}
      <ToastContainer />
      <Router>
        <Suspense fallback={<Loader />}>
          <AppRouter />
        </Suspense>
      </Router>
    </Provider>
  );
}
export default App;
