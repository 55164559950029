import axios from "axios";
import { CONFIG } from "../config/config.js";

let token = '';

if (localStorage.getItem('token')) {
    token = localStorage.getItem('token');
}

const headers = {
    Accept: 'application/json',
};

if (token) {
    headers.Authorization = `Token ${token}`;
}

export const http = axios.create({
    baseURL: CONFIG.API_ENDPOINT,
    timeout: 10000,
    headers
});

// 1cbc92eace90c8cf2643d2c0788b205260050f71