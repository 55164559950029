import React, { lazy, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
  Navigate,
  redirect,
  useSearchParams,
} from "react-router-dom";
import { http } from "../../utilits/http";
import { selectIsLoggedIn, setUser } from "../../features/userSlice";
import {
  setActiveProperty,
  setProperties,
} from "../../features/propertiesSlice";
import Layout from "../layout";

//----lazy load pages start-----//
export const LoginLayout = lazy(() => import("../login-layout"));
export const Login = lazy(() => import("../login"));
export const Dashboard = lazy(() => import("../dashboard"));
export const RoomsList = lazy(() => import("../rooms-list"));
export const PropertyDetail = lazy(() => import("../properties"));
export const RoomsDetail = lazy(() => import("../rooms-detail"));
export const PropertyOnboardView = lazy(() => import("../property-onboarding/ProperyOnboardingView"));
export const PropertyImage = lazy(() => import("../property-image"));
export const PropertyAmenities = lazy(() => import("../property-amenities"));
export const PropertyReview = lazy(() => import("../../property-review"));
export const BookingListing = lazy(() => import("../booking-listing"));
export const BookingDetails = lazy(() => import("../booking-details"));
export const Calender = lazy(() => import("../calender"));
export const Analytics = lazy(() => import("../analytics"));
export const Finance = lazy(() => import("../finance"));
export const Profile = lazy(() => import("../profile"));
export const ProfilePersonalContact = lazy(() => import("../profile-personal-contact"));
export const ProfilePrimaryContact = lazy(() => import("../profile-primary-contact"));
export const PricePerGuest = lazy(() => import("../price-per-guest"));
//----lazy load pages end-----//

const AppRouter = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const userInfo = useSelector((state) => state.user);

  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token && !isLoggedIn) {
      setLoading(true);
      http
        .get("auth/user/")
        .then((res) => {
          setLoading(false);
          const data = res?.data;
          dispatch(setUser(data));
        })
        .catch((err) => {
          setLoading(false);
        });
    } else if (!token) {
      navigate("/login");
    }
  }, []);

  const handleActivePropertyOnload = (property) => {
    const active = localStorage.getItem("active_property");
    if (active && property.find((item) => item.id == active)) {
      dispatch(setActiveProperty(property.find((item) => item.id == active)));
    } else {
      dispatch(setActiveProperty(property[0]));
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) return;
    setLoading(true);
    http
      .get("hotel/admin-property/list/")
      .then((res) => {
        const response = res?.data?.results;
        dispatch(setProperties(response));
        handleActivePropertyOnload(response);
        setLoading(false);
        if (response.length < 1) {
          navigate("/property-onboarding");
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  }, []);

  return (
    <Routes>
      <Route
        path="/login"
        element={
          !isLoggedIn ? (
            <LoginLayout showPropertyList={false}>
              <Login />
            </LoginLayout>
          ) : (
            <Navigate to="/" replace={true} />
          )
        }
      />
      <Route
        path="/dashboard"
        element={
          <Layout showPropertyList={true}>
            <Dashboard />
          </Layout>
        }
      />
      <Route
        path="/"
        index
        element={
          !isLoggedIn ? (
            <LoginLayout>
              <Login />
            </LoginLayout>
          ) : (
            <Layout showPropertyList={true}>
              <Dashboard />
            </Layout>
          )
        }
      />
      <Route
        path="/rooms"
        element={
          <Layout showPropertyList={true}>
            <RoomsList />
          </Layout>
        }
      />
      <Route
        path="/bookings"
        element={
          <Layout showPropertyList={true}>
            <BookingListing />
          </Layout>
        }
      />
      <Route
        path="/property/info"
        element={
          <Layout showPropertyList={true}>
            <PropertyDetail />
          </Layout>
        }
      />
      <Route
        path="/property/image"
        element={
          <Layout showPropertyList={true}>
            <PropertyImage />
          </Layout>
        }
      />
      <Route
        path="/property/amenities"
        element={
          <Layout showPropertyList={true}>
            <PropertyAmenities />
          </Layout>
        }
      />
      <Route
        path="/property-onboarding"
        element={
          isLoggedIn ? (
            <LoginLayout showPropertyList={false}>
              <PropertyOnboardView />
            </LoginLayout>
          ) : (
            <LoginLayout>
              <Login />
            </LoginLayout>
          )
        }
      />
      <Route
        path="/add-rooms/"
        element={
          <Layout showPropertyList={true}>
            <RoomsDetail />
          </Layout>
        }
      />
      <Route
        path="/rating-and-review/"
        element={
          <Layout showPropertyList={true}>
            <PropertyReview />
          </Layout>
        }
      />

      <Route
        path="/bookings/details/"
        element={
          <Layout showPropertyList={true}>
            <BookingDetails />
          </Layout>
        }
      />
      <Route
        path="/bookings/details/:id"
        element={
          <Layout showPropertyList={true}>
            <BookingDetails />
          </Layout>
        }
      />
      <Route
        path="/pricing/calender"
        element={
          <Layout showPropertyList={true}>
            <Calender />
          </Layout>
        }
      />
      <Route
        path="/pricing/price-per-guest"
        element={
          <Layout showPropertyList={true}>
            <PricePerGuest />
          </Layout>
        }
      />
      <Route
        path="/finance"
        element={
          <Layout showPropertyList={true}>
            <Finance />
          </Layout>
        }
      />
      <Route
        path="/analytics"
        element={
          <Layout showPropertyList={true}>
            <Analytics />
          </Layout>
        }
      />
      <Route
        path="/profile"
        element={
          <Layout showPropertyList={true}>
            <Profile />
          </Layout>
        }
      />
      <Route
        path="/profile/personal-contact"
        element={
          <Layout showPropertyList={true}>
            <ProfilePersonalContact />
          </Layout>
        }
      />
      <Route
        path="/profile/primary-contact"
        element={
          <Layout showPropertyList={true}>
            <ProfilePrimaryContact />
          </Layout>
        }
      />
    </Routes>
  );
};
export default AppRouter;
