import React from "react";
import style from './Layout.module.scss';
import Container from "../container";
import Header from "../header";
import MenuBar from "../menu-bar";

const Layout = ({ children , showPropertyList  }) => {
    return(
        <div className={style.layout} >
            <Header showPropertyList={showPropertyList} />
            <div className={style.menu}>
                <MenuBar />
            </div>
            <Container>
                {children}
            </Container>
        </div>
    )
}
export default Layout;
